export default function useBaseRoutes() {
  const route = useRoute()

  // .e.g ['', 'preview', 'pages', 'christmas-gift-ideas', 'makeup']
  // or ['', 'pages', 'christmas-gift-ideas', 'makeup']
  const path = computed(() => route.path.split('/'))

  const isIndexRoute = computed(
    () => route.path.replace(/preview\/?/, '') === '/'
  )

  const isSandboxPage = computed(() => route.path.startsWith('/sandbox'))

  const previewDirectory = computed(() => {
    return (isPreview.value && path.value[1]) || ''
  })

  const pageType = computed(() =>
    isIndexRoute.value
      ? 'homepage'
      : isSandboxPage.value // for sandbox page, the page type is the 3rd position
        ? path.value[3] // e.g. /sandbox/contentful/pages/christmas-gift-ideas
        : isPreview.value
          ? path.value[2] // e.g. /preview/pages/christmas-gift-ideas
          : path.value[1] || ''
  )

  const slugUrl = computed(() =>
    isIndexRoute.value ? 'homepage' : route.params.slugUrl?.toString() || ''
  )

  // .e.g 'preview/pages/christmas-gift-ideas'
  const baseDirectory = computed(() => {
    return [previewDirectory.value, pageType.value, route.params.slugUrl]
      .filter(Boolean)
      .join('/')
  })

  const isPreview = computed(() => {
    return path.value[1] === 'preview'
  })

  const normalizedRoutePath = computed(() => {
    const path = route.path.replace(/\/preview/, '')
    return path.startsWith('/homepage') ? '/' : path
  })

  const signInFullPath = computed(() => {
    return isIndexRoute.value || route.path === '/sign_in'
      ? '/sign_in'
      : `/sign_in?return_to=${encodeURIComponent(route.fullPath)}`
  })

  /**
   * - - - - - Internal Functions - - - - -
   */
  // Add _internalFunctions() here.

  return {
    baseDirectory,
    isPreview,
    normalizedRoutePath,
    pageType,
    signInFullPath,
    slugUrl,
  }
}
